import { jsx as _jsx } from "react/jsx-runtime";
import { renderToStaticMarkup } from "react-dom/server";
import { createTheme, Link, StyledEngineProvider, ThemeProvider, Typography, } from "@mui/material";
import { getTheme } from "../theme";
const renderer = {
    heading({ depth: level, tokens }) {
        const text = this.parser.parseInline(tokens);
        return renderToStaticMarkup(_jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: createTheme(getTheme(false)), children: _jsx(Typography, { variant: `h${level}`, dangerouslySetInnerHTML: { __html: text } }) }) }));
    },
    link({ href, title, text }) {
        return renderToStaticMarkup(_jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: createTheme(getTheme(false)), children: _jsx(Link, { href: href ?? undefined, title: title ?? undefined, dangerouslySetInnerHTML: { __html: text }, target: "_blank" }) }) }));
    },
    text({ text, }) {
        return renderToStaticMarkup(_jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: createTheme(getTheme(false)), children: _jsx(Typography, { component: "span", dangerouslySetInnerHTML: { __html: text } }) }) }));
    },
};
export default renderer;
