import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState, } from "react";
import { Box, Container, createTheme, Grid, Link, Paper, ThemeProvider, Typography, useTheme, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { deepAssign, Loader, showErrorDialog, useDialogContext, useLocation, useNavigate, useParams, } from "components-care";
import { useQuery } from "@tanstack/react-query";
import BackendHttpClient from "../../../components-care/connectors/BackendHttpClient";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { Trans, useTranslation } from "react-i18next";
import { marked } from "marked";
import SocialLogins, { enableSocialLogins } from "./SocialLogins";
import PolicyViewer from "../../../components/PolicyViewer";
import LangSelector from "./LangSelector";
import { ProviderImprintUrl, ProviderLegalName, ProviderLogoUrl, } from "../../../constants";
const useStyles = makeStyles()((theme) => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.advanced
            ? undefined
            : theme.palette.background.default,
        background: theme.palette.background.advanced || undefined,
        width: "100%",
        minHeight: "100%",
    },
    gridContainer: {
        minHeight: "100vh",
    },
    container: {
        height: "100%",
    },
    appInfo: {
        width: "100%",
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    companyLogo: {
        maxWidth: "100%",
        width: "auto",
        height: "1.5rem",
        objectFit: "contain",
    },
    appIcon: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        paddingBottom: theme.spacing(2),
    },
    langSelector: {
        left: "50%",
        transform: "translateX(-50%)",
    },
}));
export var AuthFactorType;
(function (AuthFactorType) {
    /**
     * Classic password
     */
    AuthFactorType["PASSWORD"] = "password";
    /**
     * Time-based one time password
     */
    AuthFactorType["TOPT"] = "topt";
    /**
     * Universal 2nd Factor => WebAuthn
     */
    AuthFactorType["U2F"] = "u2f";
    /**
     * managed account, used to show redirect screen
     */
    AuthFactorType["EXTERNAL_OAUTH"] = "ext_oauth";
})(AuthFactorType || (AuthFactorType = {}));
const AuthPageStateContext = React.createContext(null);
export const useAuthPageState = () => {
    const ctx = useContext(AuthPageStateContext);
    if (!ctx)
        throw new Error("Context not set");
    return ctx;
};
const AuthPageAppInfoContext = React.createContext(null);
export const useAuthPageAppInfo = () => {
    const ctx = useContext(AuthPageAppInfoContext);
    if (!ctx)
        throw new Error("Context not set");
    return ctx;
};
const CurrentProviderConfig = {
    legalName: ProviderLegalName ?? "REACT_APP_PROVIDER_LEGAL_NAME not configured!",
    logo: ProviderLogoUrl || null,
    imprintUrl: ProviderImprintUrl ??
        "javascript:alert('REACT_APP_PROVIDER_IMPRINT_URL not configured!')",
};
const AuthPageLayoutInner = (props) => {
    const { classes } = useStyles();
    const appInfo = useAuthPageAppInfo();
    const { app } = useParams();
    const { t, i18n } = useTranslation("auth");
    const [pushDialog] = useDialogContext();
    const navigate = useNavigate();
    const location = useLocation();
    // handle OAuth failure message
    useEffect(() => {
        const failureMessageId = "failure_message";
        const locationQuery = new URLSearchParams(location.search);
        const failureMessage = locationQuery.get(failureMessageId);
        if (!failureMessage)
            return;
        locationQuery.delete(failureMessageId);
        navigate({ ...location, search: locationQuery.toString() });
        void showErrorDialog(pushDialog, failureMessage);
    }, [location, navigate, pushDialog]);
    const { data: appText } = useQuery({
        queryKey: ["app-info", appInfo.id],
        queryFn: async () => {
            const contentRecord = appInfo.contents.find((entry) => entry.name === "app-info");
            if (!contentRecord)
                return "";
            const resp = await BackendHttpClient.get(contentRecord.url, null, AuthMode.Off);
            const contents = resp.data.attributes.content_translations;
            return (contents[i18n.language.split("-")[0]] ?? contents["en"] // fallback lang
            );
        },
    });
    const showPrivacyDialog = useCallback(() => {
        pushDialog(_jsx(PolicyViewer, { document: "tos-privacy" }));
    }, [pushDialog]);
    const statePack = useState({
        showSocialLogins: false,
        remainingFactors: [AuthFactorType.PASSWORD],
    });
    return (_jsx("div", { className: classes.root, children: _jsxs(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "space-between", alignContent: "stretch", wrap: "nowrap", className: classes.gridContainer, children: [_jsx(Grid, { item: true, xs: true, children: _jsx(Container, { maxWidth: "xs", className: classes.container, children: _jsx(Box, { my: 4, children: _jsxs(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "flex-start", alignContent: "stretch", className: classes.container, spacing: 4, wrap: "nowrap", children: [_jsxs(Grid, { item: true, children: [_jsx(LangSelector, { className: classes.langSelector }), _jsx(Paper, { children: _jsxs(Box, { p: 4, children: [appInfo.image.medium && (_jsx("img", { src: appInfo.image.medium, alt: appInfo.full_name, className: classes.appIcon })), _jsx(AuthPageStateContext.Provider, { value: statePack, children: props.children })] }) })] }), enableSocialLogins() && statePack[0].showSocialLogins && (_jsx(Grid, { item: true, className: classes.appInfo, children: _jsx(Paper, { children: _jsx(Box, { p: 4, children: _jsx(SocialLogins, { app: app ?? "undefined" }) }) }) })), appText && (_jsx(Grid, { item: true, className: classes.appInfo, children: _jsx(Paper, { children: _jsxs(Box, { p: 4, children: [_jsx(Typography, { variant: "h4", children: t("app-info.title") }), _jsx("div", { dangerouslySetInnerHTML: { __html: marked(appText) } })] }) }) }))] }) }) }) }), _jsx(Grid, { item: true, className: classes.footer, children: _jsx(Container, { maxWidth: "xs", children: _jsx(Box, { py: 2, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: _jsx(Trans, { t: t, i18nKey: "footer.text", values: {
                                                    HOSTNAME: window.location.hostname,
                                                    PROVIDER: CurrentProviderConfig.legalName,
                                                }, components: {
                                                    "1": (_jsx(Link, { href: "https://github.com/Samedis-care/identity-management-frontend" })),
                                                    "2": _jsx(Link, { href: CurrentProviderConfig.imprintUrl }),
                                                } }) }) }), _jsxs(Grid, { item: true, xs: 12, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: CurrentProviderConfig.logo && (_jsx("img", { src: CurrentProviderConfig.logo, alt: t("footer.logo-alt", {
                                                        PROVIDER: CurrentProviderConfig.legalName,
                                                    }), className: classes.companyLogo })) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(Typography, { align: "right", children: _jsx(Link, { onClick: showPrivacyDialog, href: "#", children: t("footer.privacy") }) }) })] })] }) }) }) })] }) }));
};
const AuthPageLayout = (props) => {
    const { app } = useParams();
    const theme = useTheme();
    const { data } = useQuery({
        queryKey: ["app-info", app],
        queryFn: () => {
            return BackendHttpClient.get(`/api/v1/app/info/${app}`, null, AuthMode.Off);
        },
    });
    if (!data)
        return _jsx(Loader, {});
    const { components_care: componentsCare, ...palette } = data.data.attributes.config.theme;
    const inner = {
        palette: palette,
        components: {
            CcActionButton: {
                styleOverrides: {
                    button: {
                        ...(componentsCare?.ui_kit?.action_button?.background_color && {
                            backgroundColor: componentsCare.ui_kit.action_button.background_color,
                            color: theme.palette.getContrastText(componentsCare.ui_kit.action_button.background_color),
                        }),
                    },
                },
            },
        },
    };
    return (_jsx(AuthPageAppInfoContext.Provider, { value: data.data.attributes, children: _jsx(ThemeProvider, { theme: (outer) => createTheme(deepAssign({}, outer, inner)), children: _jsx(AuthPageLayoutInner, { ...props }) }) }));
};
export default React.memo(AuthPageLayout);
