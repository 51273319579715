import { BackendError, RailsApiClient } from "components-care";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { destroySession, handleAuth, } from "../../pages/components/AuthProvider";
import * as Sentry from "@sentry/react";
class BackendHttpClient extends RailsApiClient {
    constructor() {
        super(() => handleAuth(), async (_response, responseData, method, url, args, body, auth) => {
            const rsp = responseData;
            const success = rsp.meta?.msg?.success;
            const error = rsp.meta?.msg?.error;
            const message = rsp.meta?.msg?.message;
            if (!success) {
                if (error === "invalid_token" ||
                    error === "token_invalid" ||
                    error === "token_expired" ||
                    error === "otp_too_many_tries") {
                    if (auth === AuthMode.Off) {
                        throw new Error("Authentication is needed, but wasn't specified");
                    }
                    if (auth !== AuthMode.Try) {
                        await destroySession();
                        // retry
                        return this.request(method, url, args, body, auth);
                    }
                }
                throw new BackendError(message || error || "Invalid response", error);
            }
            return responseData;
        }, undefined, undefined, (error) => {
            if (error.name === "NetworkError")
                return;
            if (error.name === "BackendError")
                return;
            Sentry.captureException(error);
        });
    }
}
export default new BackendHttpClient();
