import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import BackendHttpClient from "../components-care/connectors/BackendHttpClient";
import { marked } from "marked";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { useTranslation } from "react-i18next";
import { Loader } from "components-care";
const getPolicyContent = (policy, lang) => {
    lang = lang.split("-")[0];
    const { content_translations } = policy.data.attributes;
    if (lang in content_translations)
        return content_translations[lang];
    if ("en" in content_translations)
        return content_translations["en"];
    console.warn("Policy not available in english", policy);
    return Object.values(content_translations)[0]; // any language
};
const PolicyViewerContent = (props) => {
    const { i18n } = useTranslation();
    const { isLoading, data, error } = useQuery({
        queryKey: [props.document],
        queryFn: () => BackendHttpClient.get(`/api/v1/identity-management/content_acceptance/${encodeURI(props.document)}`, null, AuthMode.Off),
    });
    const content = useMemo(() => {
        if (!data)
            return null;
        const policy = getPolicyContent(data, i18n.language);
        return policy ? marked(policy) : null;
    }, [data, i18n.language]);
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(Loader, {}), error && _jsx(Typography, { children: error.message }), content && _jsx("div", { dangerouslySetInnerHTML: { __html: content } })] }));
};
export default React.memo(PolicyViewerContent);
